function sendSearchToNSEDatalayer(data) {
  const pageHierarchy = window && window.dataLayer[0]?.page?.hierarchy || [];
  if (pageHierarchy) {
    data._datalayer.page.hierarchy = pageHierarchy;
  }
  data._datalayer.page.type = 'serps';

  const dataAnalytics = {
    event: 'serps_view',
    search_term: data._searching_term,
    stype: data._stype,
    page: data._datalayer.page,
    search_refinement: data.search_refinement,
    business_search: 'elcorteingles',
  };

  if(data._datalayer.page.total_products <= 0) dataAnalytics.no_results = true;

  window.dataLayer.push(dataAnalytics);
}
function sendSearchProductToNSEDatalayer(data) {
  if (data._datalayer.products?.length) {
    const pageHierarchy = window && window.dataLayer[0]?.page?.hierarchy?.join('/') || [];
    for (const product of data._datalayer.products) {
      product.vendor = 'SERPS/' + pageHierarchy;
    }
  }
  let index_pushed = 0;
  for (const dataLayer of window.dataLayer) {
    if(dataLayer.event === 'serps_products_push') {
      index_pushed = dataLayer.index_pushed
    }
  }
  const dataProductsAnalytics = {
    event: 'serps_products_push',
    index_pushed: index_pushed + 1,
    [`products_${index_pushed + 1}`]: data._datalayer.products,
    product_search_term: data._searching_term,
    product_search_info: data.product_search_info
  };
  window.dataLayer.push(dataProductsAnalytics);
}

function sendClickNSEPromotion(placements) {
  let artworkName = '';
  if (placements.compositions.length && placements.compositions[0].artworks.length) {
    artworkName = placements.compositions[0].artworks[0][0].artwork.name;
  }
  const dataPromotionAnalytics = {
    event: "promotion_click",
    promotion: {
      id: placements.id,
      name: placements.name,
      pos: placements.placement_name,
      creative: artworkName
    } 
  }
  window.dataLayer.push(dataPromotionAnalytics);
}
function sendRenderNSEPromotions(placements) {
  let artworkName = '';
  if (placements.compositions.length && placements.compositions[0].artworks.length) {
    artworkName = placements.compositions[0].artworks[0][0].artwork.name;
  }
  const cdp_index_push = window.dataLayer.filter((ev) => ev.event === 'promotions_push').length + 1;
  const dataPromotionAnalytics = {
    event: "promotions_push",
    cdp_index_push,
    [`promotion_${cdp_index_push}`]: {
      id: placements.id,
      name: placements.name,
      pos: placements.placement_name,
      creative: artworkName
    } 
  }
  window.dataLayer.push(dataPromotionAnalytics);
}
function sendChipClickedToNSEDatalayer(data) {
  const dataAnalytics = {
    event: 'custom_event',
    event_data: {
      event_name: 'serps products filters',
      event_category: 'serps products filters',
      event_action: 'category navigation',
      event_label: data?.label ? data.label : 'Todo'
    }
  };
  window.dataLayer.push(dataAnalytics);
}
module.exports = {
  sendSearchToNSEDatalayer,
  sendSearchProductToNSEDatalayer,
  sendClickNSEPromotion,
  sendRenderNSEPromotions,
  sendChipClickedToNSEDatalayer
};