const UtilsAnalytics = require('CommonUtils/analytics.js');
const ScriptUtils = require('CommonUtils/addScript');

function loadVideoController(scope) {
  
  const videoScope = (scope) ? document.querySelector(scope) : document;
  
  if (typeof videoScope === 'undefined') return;
  const videoData = videoScope.querySelectorAll('.artwork video:not(.js-videojs)');
  const videosJs  = videoScope.querySelectorAll('.artwork video.js-videojs');
  if (videoData?.length) {    
    videoData.forEach((video) => {      
      video.oncanplay = function () {
        initVideoControls(video);
      }
    })
    window.addEventListener('load', () => {
      loadVideoConfiguration(videoScope);
    });    
  }
  if (videosJs?.length) {
    loadVideoScript(videoScope);
  }
};

function loadVideoScript(videoScope) {
  if (document.getElementById('video-store')) {
    loadVideoSwitchScript(videoScope);
  } else {
    ScriptUtils.addScriptFromUrl(
      'https://cdn.grupoelcorteingles.es/statics/front-msh2-eci-non-food/assets/javascripts/library/external/video-store.js',
      'high',
      'video-store',
      loadVideoSwitchScript
    );
  }
}

function loadVideoSwitchScript(videoScope) {
  if (document.getElementById('video-switch')) {
    loadVideoConfiguration(videoScope);
  } else {
    ScriptUtils.addScriptFromUrl(
      'https://cdn.grupoelcorteingles.es/statics/front-msh2-eci-non-food/assets/javascripts/library/external/video-switch-res.js',
      'high',
      'video-switch'
    );
  }
}

function loadVideoConfiguration (scope) {
  const videoData = scope.querySelectorAll('.artwork video');
  videoData.forEach((video) => {   
    if(scope) videojs(video);     
    initVideoControls(video);
  });
}

function isVisible(el) {
  const videoDisplay = el && getComputedStyle(el).getPropertyValue('display');
  return videoDisplay !== '' && videoDisplay !== 'none';
}

function initVideoControls(video) {
  try {
    // Set video wrapper
    let videoWrapper = (video.classList.contains('_canvas')) ? video.closest('div.canvas') : video.closest('div');
    if( video.classList.contains('exclude') ) {
      videoWrapper = video.closest('.exclude-wrapper');
    }
    let videojsPlayer = undefined;
    if (!isVisible(video)) {
      return;
    }

    // Artworks with type 'video' have their dom nodes modified on page load by videojs library. Other artworks can
    // contain videos, but they are not affected by videojs. That's why we must adapt the behaviour of this function
    // depending on the artwork type.
    if (/^vjs_video/.test(video.id)) {
      const wrapper = video.closest('div.videojs-wrp');
      if (wrapper) {
        videoWrapper = wrapper;
      }
      videojsPlayer = window.videojs.getPlayer(video.id);
      if (videojsPlayer && videojsPlayer.controls()) {
        videojsPlayer.on('play', () => {
          UtilsAnalytics.sendVideoPlayerEvent('play', videojsPlayer.el().dataset.name);
        });
        videojsPlayer.on('pause', () => {
          UtilsAnalytics.sendVideoPlayerEvent('pause', videojsPlayer.el().dataset.name);
        });
        videojsPlayer.on('ended', () => {
          UtilsAnalytics.sendVideoPlayerEvent('finished', videojsPlayer.el().dataset.name);
        });
      }
    } else {
      const artworkContainer = video.closest('div[id^=artwork-]');
      const videoName = artworkContainer && artworkContainer.dataset.name;
      if (video.controls) {
        video.onplay = () => {
          UtilsAnalytics.sendVideoPlayerEvent('play', videoName);
        };
        video.onpause = () => {
          UtilsAnalytics.sendVideoPlayerEvent('pause', videoName);
        };
        video.onended = () => {
          UtilsAnalytics.sendVideoPlayerEvent('finished', videoName);
        };
      }
    }

    const hasAudio = () => {
      return videojsPlayer
        ? Boolean(videojsPlayer.audioTracks().length) || checkVideoAudio(videojsPlayer)
        : checkVideoAudio(video)
    };

    const checkVideoAudio = (video) => {
      return video.mozHasAudio ||
            Boolean(video.webkitAudioDecodedByteCount) ||
            Boolean(video.audioTracks && video.audioTracks.length);
    };

    // If the video doesn't have audio or controls are active, skips addition of mute custom control
    if (!hasAudio() || (videojsPlayer && videojsPlayer.controls()) || video.controls) {
      videoWrapper.classList.remove('video-wrapper');
      videoWrapper.classList.remove('video-wrapper--unmuted');
      return;
    }

    // Custom mute button added when no controls available and video has audio.    
    const isMuted = () => {
      return videojsPlayer
        ? videojsPlayer.muted() || videojsPlayer.volume() <= 0
        : video.muted || video.volume <= 0;
    };
    const setMuted = (isMuted, control) => {
      if (videojsPlayer) {
        videojsPlayer.muted(isMuted);
      } else {
        if(!control && !video.muted) return;
        video.muted = isMuted;
      }
    };

    const isAutoplay = () => {
      return videojsPlayer ? videojsPlayer.autoplay() : video.autoplay;
    };
    
    if(videoWrapper) {
      videoWrapper.classList.add('video-wrapper');
      const toggleMute = () => videoWrapper.classList.toggle('video-wrapper--unmuted', !isMuted());
      if (videojsPlayer) {
        videojsPlayer.on('volumechange', () => toggleMute());
      } else {
        video.onvolumechange = () => toggleMute();
      }
      toggleMute();

      // Check own mute control
      videoWrapper.onclick = (e) => {
        const after = getComputedStyle(videoWrapper, ':after');
        if (!after) return;      
        const minY = parseFloat(after.top);
        const maxY = parseFloat(after.top) + parseFloat(after.height);

        const minX = parseFloat(after.left);
        const maxX = parseFloat(after.left) + parseFloat(after.width);

        if ((e.offsetY - minY) * (e.offsetY - maxY) > 0) return;
        if ((e.offsetX - minX) * (e.offsetX - maxX) > 0) return;
        e.preventDefault();
        setMuted(!isMuted(), true);
      };

      // Check picture in picture mode
      // NOTE: https://caniuse.com/?search=Picture%20element
      if (videojsPlayer) {
        videojsPlayer.on('enterpictureinpicture', () => {
          videoWrapper.classList.add('video-wrapper--disabled');
        });
        videojsPlayer.on('leavepictureinpicture', () => {
          videoWrapper.classList.remove('video-wrapper--disabled');
        });
      } else {
        video.onenterpictureinpicture = () => videoWrapper.classList.add('video-wrapper--disabled');
        video.onleavepictureinpicture = () => videoWrapper.classList.remove('video-wrapper--disabled');
      }

      // Check display events
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            if (video.reloadMuteState) {
              video.reloadMuteState = false;
              setMuted(video.previousMutedState);
            }
          } else if (!video.paused && !video.muted) {
            video.reloadMuteState = true;
            video.previousMutedState = video.muted;
            setMuted(true);
          }
        });
      });
      observer.observe(video);
    }
    // Check autoplay / muted options
    setMuted(isAutoplay());
  } catch (error) {
    console.log('Error processing video artwork.', error);
  }
}

module.exports = {
  loadVideoController,
  loadVideoConfiguration,
}